.contact-page {
    background: #0F172A;
    min-height: 100vh;
    padding-top: 8rem;
    padding-bottom: 8rem;
}


.header {
    text-align: center;
    margin-bottom: 6rem;
}

.title {
    color: #00FFA5;
    font-weight: bold;
    margin-bottom: 2rem;
    font-size: 2.5rem;
}

.subtitle {
    color: #94A3B8;
}


.form-container {
    margin-top: 4rem;
}


.input-field .MuiOutlinedInput-root {
    color: #94A3B8;
}

.input-field .MuiOutlinedInput-root fieldset {
    border-color: #334155;
}

.input-field .MuiOutlinedInput-root:hover fieldset {
    border-color: #00FFA5;
}

.input-field .MuiOutlinedInput-root.Mui-focused fieldset {
    border-color: #00FFA5;
}

.input-field .MuiInputLabel-root {
    color: #64748B;
}

.input-field .MuiInputLabel-root.Mui-focused {
    color: #00FFA5;
}


.cta-container {
    text-align: center;
    margin-top: 4rem;
}

.cta-button {
    background-color: #00FFA5;
    color: #0F172A;
    padding: 1rem 3rem;
    border-radius: 50px;
    font-weight: bold;
    font-size: 1.1rem;
    text-transform: none;
    transition: all 0.3s ease;
    min-width: 200px;
}

.cta-button:hover {
    background-color: #00E694;
    transform: scale(1.05);
    box-shadow: 0 4px 20px rgba(0, 255, 165, 0.4);
}
