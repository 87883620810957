.game-page {
    min-height: 100vh;
    background: linear-gradient(135deg, rgba(15, 23, 42, 1) 0%, rgba(0, 0, 0, 0.8) 100%);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 4rem;
    text-align: center;
}

.game-title {
    color: #00FFA5;
    margin-bottom: 1rem;
    font-weight: bold;
    text-shadow: 0 0 15px rgba(0, 255, 165, 0.7);
    font-size: 3rem;
    letter-spacing: 2px;
    text-transform: uppercase;
}

.game-cards {
    display: flex;
    gap: 2rem;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 2rem;
}

.game-card {
    background: #1E293B;
    border-radius: 10px;
    padding: 1.5rem;
    width: 300px;
    text-align: center;
    transition: transform 0.3s, box-shadow 0.3s;
    cursor: pointer;
    box-shadow: 0 4px 10px rgba(0, 255, 165, 0.3);
}

.game-card:hover {
    transform: scale(1.05);
    box-shadow: 0 6px 15px rgba(0, 255, 165, 0.5);
}

.game-card img {
    width: 100%;
    border-radius: 8px;
}

.game-card h3 {
    margin-top: 1rem;
    color: #FFF;
    font-size: 1.5rem;
}
