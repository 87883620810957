.privacy-policy-page {
    background: #0F172A;
    min-height: 100vh;
    padding: 1rem 0;
}

.content-container {
    padding-top: 2rem;
    padding-bottom: 2rem;
}


.header {
    text-align: center;
    margin-bottom: 4rem;
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
}

.title {
    color: #00FFA5;
    font-weight: bold;
    margin-bottom: 2rem;
    font-size: 2.2rem;
    line-height: 1.2;
}

.subtitle {
    color: #94A3B8;
    font-size: 1.2rem;
    padding-left: 1rem;
    padding-right: 1rem;
}


.content {
    color: #94A3B8;
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
}

.section-title {
    color: #00FFA5;
    margin-top: 4rem;
    margin-bottom: 2rem;
    font-size: 1.4rem;
}

.content p {
    font-size: 0.95rem;
    line-height: 1.6;
    margin-bottom: 2rem;
}


@media (min-width: 768px) {
    .title {
        font-size: 3rem;
    }

    .subtitle {
        font-size: 1.5rem;
        padding-left: 4rem;
        padding-right: 4rem;
    }

    .section-title {
        font-size: 1.8rem;
    }

    .content p {
        font-size: 1rem;
    }
}
