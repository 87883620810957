.roulette-container {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-size: cover;
    background-position: center;
    color: #fff;
    overflow: hidden;
}

.roulette-container::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    z-index: 0;
}


.roulette-container > * {
    position: relative;
    z-index: 1;
}


.roulette-wheel {
    width: 300px;
    height: 300px;
    border-radius: 50%;
    background: conic-gradient(
            red 0deg 30deg, black 30deg 60deg, red 60deg 90deg, black 90deg 120deg,
            red 120deg 150deg, black 150deg 180deg, red 180deg 210deg, black 210deg 240deg,
            red 240deg 270deg, black 270deg 300deg, red 300deg 330deg, black 330deg 360deg
    );
    border: 8px solid #ffd700;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 0 50px rgba(255, 215, 0, 0.8);
    position: relative;
    transition: transform 4s cubic-bezier(0.25, 1, 0.5, 1);
}


.spinning {
    transform: rotate(1440deg);
}


.result {
    width: 90px;
    height: 90px;
    border-radius: 50%;
    font-size: 36px;
    font-weight: bold;
    color: white;
    background: rgba(0, 0, 0, 0.8);
    border: 4px solid #fff;
    box-shadow: 0 0 20px rgba(255, 255, 255, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    z-index: 2;
}


.spin-button {
    margin-top: 30px;
    padding: 18px 40px;
    font-size: 24px;
    font-weight: bold;
    border: none;
    background: linear-gradient(45deg, #ffcc00, #ff8800);
    cursor: pointer;
    border-radius: 50px;
    transition: all 0.4s ease-in-out;
    color: #fff;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
    box-shadow: 0 6px 20px rgba(255, 136, 0, 0.6);
}


.spin-button:disabled {
    background: #555;
    cursor: not-allowed;
    box-shadow: none;
}


.spin-button:hover:not(:disabled) {
    background: linear-gradient(45deg, #ff8800, #ffcc00);
    transform: scale(1.1);
}


.scoreboard {
    font-size: 28px;
    font-weight: bold;
    margin-bottom: 20px;
    text-align: center;
    padding: 15px 30px;
    border-radius: 15px;
    background: rgba(255, 215, 0, 0.2);
    backdrop-filter: blur(8px);
    box-shadow: 0 0 20px rgba(255, 215, 0, 0.6);
    border: 2px solid rgba(255, 215, 0, 0.8);
    transition: all 0.3s ease-in-out;
}


.scoreboard p {
    margin: 5px 0;
    text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
    color: #ffcc00;
    letter-spacing: 1px;
}


@keyframes score-update {
    0% { transform: scale(1); }
    50% { transform: scale(1.1); }
    100% { transform: scale(1); }
}

.scoreboard p:nth-child(1) {
    font-size: 32px;
    color: #fff;
    font-weight: bold;
}

.scoreboard p:nth-child(2) {
    font-size: 24px;
    color: #ffeb3b;
}


.scoreboard.score-updated {
    animation: score-update 0.3s ease-in-out;
}

