.aboutPage {
    background: #0F172A;
    min-height: 100vh;
}


.heroSection {
    position: relative;
    height: 60vh;
    padding-top: 50px;
    display: flex;
}

.heroBackground {
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.7)), url('/public/assets/aboutBackground.png');
    background-size: cover;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
}



.heroContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    z-index: 2;
    margin-left: 8rem;
    margin-bottom: 8rem;
}

.title, .subtitle {
    display: block;
    text-align: center;
}

.title {
    color: #00FFA5;
    font-weight: bold;
    margin-bottom: 1.5rem;
}

.subtitle {
    color: #94A3B8;
    margin-bottom: 2rem;
}


.contentContainer {
    padding: 4rem 0;
}

.sectionTitle {
    color: #00FFA5;
    margin-bottom: 1.5rem;
}

.sectionText {
    color: #94A3B8;
    margin-bottom: 2rem;
    line-height: 1.8;
}


.ctaContainer {
    text-align: center;
    margin-top: 4rem;
}

.ctaButton {
    background-color: #00FFA5 !important;
    color: #0F172A !important;
    padding: 1rem 3rem !important;
    border-radius: 50px !important;
    font-weight: bold !important;
    font-size: 1.2rem !important;
    text-transform: none !important;
    transition: all 0.3s ease !important;
}

.ctaButton:hover {
    background-color: #00E694 !important;
    transform: scale(1.1);
    box-shadow: 0 4px 20px rgba(0, 255, 165, 0.4);
}
