.age-dialog .MuiPaper-root {
    background-color: #1E293B !important;
    color: white !important;
    border-radius: 16px !important;
    padding: 24px !important;
}


.age-dialog-title {
    font-size: 2rem !important;
    text-align: center !important;
}


.age-dialog-content-text {
    color: #94A3B8 !important;
    font-size: 1.2rem !important;
    text-align: center !important;
}


.age-dialog-actions {
    justify-content: center !important;
    gap: 16px !important;
}


.age-yes-button {
    background-color: #00FFA5 !important;
    color: #0F172A !important;
    min-width: 120px !important;
}

.age-yes-button:hover {
    background-color: #00E694 !important;
}


.age-no-button {
    border-color: #FF4567 !important;
    color: #FF4567 !important;
    min-width: 120px !important;
}

.age-no-button:hover {
    border-color: #FF1234 !important;
}


.age-blocked-container {
    position: fixed !important;
    top: 0 !important;
    left: 0 !important;
    width: 100% !important;
    height: 100% !important;
    background-color: rgba(0,0,0,0.9) !important;
    z-index: 9999 !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    flex-direction: column !important;
    color: white !important;
    text-align: center !important;
    padding: 32px !important;
}

.age-blocked-title {
    margin-bottom: 24px !important;
    color: #FF4567 !important;
}

.age-blocked-text {
    font-size: 1.5rem !important;
}